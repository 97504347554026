body {
  margin: 0;
  font-family: 'Noah Head';
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Thin.otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ UltraLight.otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Light.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Bold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ ExtraBold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Black.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Heavy.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Thin\ Italic.otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ UltraLight\ Italic.otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Light\ Italic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Regular\ Italic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Medium\ Italic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Bold\ Italic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ ExtraBold\ Italic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Black\ Italic.otf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Noah Head';
  src: url('./fonts/Noah\ Head\ Heavy\ Italic.otf');
  font-weight: 900;
  font-style: italic;
}
